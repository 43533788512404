import React, { useCallback, useEffect } from 'react';
// routes
import Router from './routes';
// hooks
import useAuth from 'src/hooks/useAuth';
// components
import ThemeConfig from './theme';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import { useLocation, useNavigate } from 'react-router-dom';
import { COMPLIANCE_PATHS } from './routes/paths';
import axiosInstance from 'src/utils/axios';
import AchievementModal from './components/AchievementModal/AchievementModal';
import './App.css';
import './styles/Main.scss';

import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';

const SETUP_PATHNAME = ['/', '/workbench', '/workbench/'];
// build commit
// ----------------------------------------------------------------------
export default function App() {
  LicenseManager.setLicenseKey(
    'Using_this_{AG_Grid}_Enterprise_key_{AG-070937}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Pronto_Housing}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{ProntoHousing}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{ProntoHousing}_need_to_be_licensed___{ProntoHousing}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{5_November_2025}____[v3]_[01]_MTc2MjMwMDgwMDAwMA==463b55cdf353858765b70a1b8872823a'
  );
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isInitialized, isAuthenticated, user } = useAuth();

  const checkSetupProperties = useCallback(() => {
    axiosInstance
      .get(`property_portfolio/property/?fields=setup_completed`)
      .then((response) => {
        if (response.status === 200) {
          const hasPropertiesToSetup = response.data.some(
            (property) => property.setup_completed === false
          );
          if (hasPropertiesToSetup) {
            navigate(COMPLIANCE_PATHS.property.setupList);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [navigate]);

  useEffect(() => {
    if (
      isAuthenticated &&
      isInitialized &&
      user.isCompliance &&
      (SETUP_PATHNAME.includes(pathname) || pathname.includes('auth/'))
    ) {
      checkSetupProperties();
    }
  }, [isAuthenticated, isInitialized, user]); // eslint-disable-line

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <NotistackProvider>
            <ScrollToTop />
            <GoogleAnalytics />
            {isInitialized ? <Router /> : <LoadingScreen />}
          </NotistackProvider>
        </RtlLayout>
        <AchievementModal />
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
