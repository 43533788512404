import { ColDef, INumberFilterParams } from 'ag-grid-community';
import {
  addThousandsComma,
  currencyFormatter,
  dateComparator,
  dateFormatter,
  parseProgress,
} from './ApplicantsTableUtils';
import React from 'react';
import AGCategoryStatus, { AGCategoryStatusProps } from './AGCategoryStatus';
import AGDatePicker from './AGDatePicker';
import AGFileOwner, { AGFileOwnerProps } from './AGFileOwner';
import { Link } from 'react-router-dom';

export const RentersDashboardColDefs: ColDef[] = [
  {
    headerName: 'Head of Household',
    field: 'hoh_name',
    cellRenderer: (params) => {
      return (
        <Link to={params.data.certification_link}>{params.data.hoh_name}</Link>
      );
    },
    filter: true,
    sortable: true,
    wrapText: true,
    minWidth: 150,
  },
  {
    headerName: 'Household Size',
    field: 'household_size',
    filter: 'agNumberColumnFilter',
    filterParams: {
      maxNumConditions: 1,
      closeOnApply: true,
    } as INumberFilterParams,
    sortable: true,
    minWidth: 150,
  },
  {
    headerName: 'Household Income',
    field: 'household_income',
    filter: 'agNumberColumnFilter',
    filterParams: {
      maxNumConditions: 1,
      closeOnApply: true,
    } as INumberFilterParams,
    valueFormatter: (params) => currencyFormatter(params.data.household_income),
    sortable: true,
    minWidth: 150,
  },
  {
    headerName: 'Log Number',
    field: 'log_number',
    filter: 'agNumberColumnFilter',
    filterParams: {
      maxNumConditions: 1,
      closeOnApply: true,
    } as INumberFilterParams,
    valueFormatter: (params) => addThousandsComma(params.data.log_number),
    sortable: true,
    minWidth: 140,
  },
  {
    headerName: 'Batch Number',
    field: 'batch_number',
    filter: 'agSetColumnFilter',
    sortable: true,
    wrapText: true,
    minWidth: 140,
  },
  {
    headerName: 'Preference Groups',
    field: 'preference_groups',
    filter: 'agSetColumnFilter',
    filterParams: {
      enableAndOr: true,
    },
    sortable: true,
    wrapText: true,
    valueFormatter: (params) => {
      if (!params.value) {
        return '';
      }
      return params.value;
    },
    minWidth: 150,
  },
  {
    headerName: 'Property Name',
    field: 'property_name',
    cellRenderer: (params) => {
      return (
        <Link
          to={`${params.data.property_link}?t1=dashboard&t2=available-units`}
        >
          {params.data.property_name}
        </Link>
      );
    },
    filter: 'agSetColumnFilter',
    wrapText: true,
    sortable: true,
    minWidth: 135,
  },
  {
    headerName: 'Building Name',
    field: 'building_name',
    filter: 'agSetColumnFilter',
    sortable: true,
    minWidth: 135,
  },
  {
    headerName: 'Building Number',
    field: 'building_number',
    filter: 'agSetColumnFilter',
    sortable: true,
    minWidth: 135,
  },
  {
    headerName: 'Unit Name',
    field: 'unit_name',
    filter: 'agSetColumnFilter',
    sortable: true,
    minWidth: 120,
  },
  {
    headerName: 'Unit Size',
    field: 'unit_size',
    filter: 'agNumberColumnFilter',
    sortable: true,
    minWidth: 115,
  },
  {
    headerName: 'Unit Type',
    field: 'unit_type',
    filter: 'agSetColumnFilter',
    sortable: true,
    minWidth: 115,
  },
  {
    headerName: 'Unit Programs',
    field: 'unit_programs',
    filter: 'agSetColumnFilter',
    sortable: true,
    wrapText: true,
    valueFormatter: (params) => {
      if (!params.value) {
        return '';
      }
      return params.value;
    },
    minWidth: 140,
  },
  {
    headerName: 'Applicant Pool Name',
    field: 'applicant_pool_name',
    filter: 'agSetColumnFilter',
    wrapText: true,
    sortable: true,
    minWidth: 150,
  },
  {
    headerName: 'AMI',
    field: 'amis',
    filter: 'agSetColumnFilter',
    sortable: true,
    valueFormatter: (params) => {
      if (!params.value) {
        return '';
      }
      return params.value;
    },
    cellStyle: { whiteSpace: 'pre-wrap' },
    minWidth: 110,
  },
  {
    headerName: 'Unit AMI',
    field: 'unit_ami',
    filter: 'agNumberColumnFilter',
    sortable: true,
    minWidth: 110,
  },
  {
    headerName: 'File Owner',
    field: 'compliance_user_name',
    filter: 'agSetColumnFilter',
    editable: true,
    sortable: true,
    minWidth: 180,
    cellRenderer: AGFileOwner,
    cellRendererParams: (params): AGFileOwnerProps => {
      return {
        certificationId: params.data.id,
        complianceUserName: params.value,
      };
    },
  },
  {
    headerName: 'Certification Name',
    field: 'certification_name',
    filter: 'agSetColumnFilter',
    wrapText: true,
    sortable: true,
    minWidth: 160,
  },
  {
    headerName: 'Category Name',
    field: 'category_name',
    filter: 'agSetColumnFilter',
    sortable: true,
    wrapText: true,
    minWidth: 140,
  },
  {
    headerName: 'Status',
    field: 'status_name',
    filter: 'agSetColumnFilter',
    wrapText: true,
    sortable: true,
    minWidth: 150,
    cellRenderer: AGCategoryStatus,
    cellRendererParams: (params): AGCategoryStatusProps => {
      return {
        categoryId: params.data.category_id,
        certificationId: params.data.id,
        statusId: params.data.status_id,
        statusName: params.data.status_name,
        isApproved: params.data.is_approved,
        isTerminal: params.data.is_terminal,
      };
    },
  },
  {
    headerName: 'Questionnaire Progress',
    field: 'questionnaire_progress',
    filter: 'agNumberColumnFilter',
    sortable: true,
    minWidth: 170,
    cellRenderer: (params) => {
      return <span className="progressColor">{Math.floor(params.value)}%</span>;
    },
    cellClassRules: {
      noProgressColor: (params) => {
        return params.data.questionnaire_progress === 0;
      },
      mediumProgressColor: (params) => {
        return (
          params.data.questionnaire_progress > 0 &&
          params.data.questionnaire_progress <= 70
        );
      },
      goodProgressColor: (params) => {
        return params.data.questionnaire_progress > 70;
      },
    },
  },
  {
    headerName: 'Total Progress',
    field: 'total_progress',
    filter: 'agNumberColumnFilter',
    sortable: true,
    minWidth: 150,
    cellRenderer: (params) => {
      return <span className="progressColor">{Math.floor(params.value)}%</span>;
    },
    cellClassRules: {
      noProgressColor: (params) => {
        const progress = parseProgress(params.data.total_progress);
        return progress === 0;
      },
      mediumProgressColor: (params) => {
        const progress = parseProgress(params.data.total_progress);
        return progress > 0 && progress <= 70;
      },
      goodProgressColor: (params) => {
        const progress = parseProgress(params.data.total_progress);
        return progress > 70;
      },
    },
  },
  {
    headerName: 'Unread Communications',
    field: 'unread_communications',
    filter: 'agNumberColumnFilter',
    cellRenderer: (params) => {
      return (
        <Link to={`${params.data.certification_link}?t1=communications`}>
          {params.data.unread_communications}
        </Link>
      );
    },
    sortable: true,
    minWidth: 185,
  },
  {
    headerName: 'Effective Date',
    field: 'effective_date',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateComparator,
      buttons: ['clear'],
    },
    valueFormatter: (params) => dateFormatter(params.data.effective_date),
    sortable: true,
    minWidth: 135,
  },
  {
    headerName: 'Deadline',
    field: 'deadline_date',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateComparator,
      buttons: ['clear'],
    },
    valueFormatter: (params) => dateFormatter(params.data.deadline_date),
    cellRenderer: AGDatePicker,
    cellRendererParams: (params) => {
      return {
        certificationId: params.data.id,
      };
    },
    sortable: true,
    minWidth: 145,
  },
  {
    headerName: 'Lease Start',
    field: 'lease_start',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateComparator,
      buttons: ['clear'],
    },
    valueFormatter: (params) => dateFormatter(params.data.lease_start),
    sortable: true,
    minWidth: 120,
  },
  {
    headerName: 'Lease End',
    field: 'lease_end',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateComparator,
      buttons: ['clear'],
    },
    valueFormatter: (params) => dateFormatter(params.data.lease_end),
    sortable: true,
    minWidth: 120,
  },
  {
    headerName: 'Move In Date',
    field: 'move_in_date',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateComparator,
      buttons: ['clear'],
    },
    valueFormatter: (params) => dateFormatter(params.data.move_in_date),
    sortable: true,
    minWidth: 120,
  },
  {
    headerName: 'Move Out Date',
    field: 'move_out_date',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateComparator,
      buttons: ['clear'],
    },
    valueFormatter: (params) => dateFormatter(params.data.move_out_date),
    sortable: true,
    minWidth: 120,
  },
  {
    headerName: 'Latest Status Note',
    field: 'latest_status_note',
    filter: 'agTextColumnFilter',
    sortable: true,
    wrapText: true,
    minWidth: 200,
    cellRenderer: (params) => {
      return (
        <span className="latestNote">{params.data.latest_status_note}</span>
      );
    },
  },
];
