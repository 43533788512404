import { createContext, useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from 'src/utils/axios';
import {
  setSessionTokenDetails,
  removeTokensMetadata,
  removeUserPilotFromLocalStorage,
  cleanupTokens,
  isTokenMetaValid,
} from 'src/utils/jwt';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DASHBOARD_GROUPS } from '../utils/dashboard';
import { datadogRum } from '@datadog/browser-rum';
import { environmentsWithDatadog, getEnvironmentName } from 'src/AppUtils';
import { userService } from '../services/User.service';
import { setHouseholdMember, setMemberType } from '../redux/slices/Main/main';
import { setCertification, setHousehold } from '../redux/slices/Tenant/Tenant';
import useBroadcastChannel from 'src/hooks/useBroadcastChannel';
import { getFeatureFlags } from 'src/redux/slices/FeatureFlags';

// ----------------------------------------------------------------------

const identifyDataDogUser = (user) => {
  if (!environmentsWithDatadog.includes(getEnvironmentName())) {
    return;
  }

  datadogRum.setUser({
    id: user.id,
    name: user.displayName,
    email: user.email,
    company: user?.property_owner_companies[0]?.name || '',
    property: user?.property?.name || '',
  });
};

// calculates the user permissions and identify the user on the different
// external services
const buildUserAuthPayload = async (userdata = {}, flags = {}) => {
  const user = { ...userdata };

  user.displayName = `${user.first_name} ${user.last_name}`;
  user.hasDashboard = !!user.groups
    .map((g) => g.name.toLowerCase())
    .find(
      (g) =>
        g === DASHBOARD_GROUPS.compliance_specialist.toLowerCase() ||
        g === DASHBOARD_GROUPS.compliance_manager.toLowerCase()
    );
  user.isProntoAdmin = !!user.groups
    .map((g) => g.name.toLowerCase())
    .find((g) => g === 'pronto admin');
  user.isCompliance = !!user.groups
    .map((g) => g.name.toLowerCase())
    .find((g) => g === 'compliance');
  user.isComplianceManager = !!user.groups
    .map((g) => g.name.toLowerCase())
    .find((g) => g === DASHBOARD_GROUPS.compliance_manager.toLowerCase());
  user.isComplianceSpecialist = !!user.groups
    .map((g) => g.name.toLowerCase())
    .find((g) => g === DASHBOARD_GROUPS.compliance_specialist.toLowerCase());
  user.isCertificationWorkbench =
    !!user.groups
      .map((g) => g.name.toLowerCase())
      .find((g) => g === 'certification workbench') && user.isComplianceManager;
  user.canUnapprovedCertification = !!user.groups
    .map((g) => g.name.toLowerCase())
    .find((g) => g === 'can unapproved certification');
  user.canRemoveHouseholdMembers = !!user.groups
    .map((g) => g.name.toLowerCase())
    .find((g) => g === 'compliance can delete hhm');
  user.canDeleteQuestionnaire = !!user.groups
    .map((g) => g.name.toLowerCase())
    .find((g) => g === 'can delete questionnaire');

  const { data: teams } = await axios.get(
    `teams/compliance_team/?managers__id=${user.id}`
  );
  user.teams = teams;
  user.team = teams?.length ? teams[0] : null;

  identifyDataDogUser(user);

  return user;
};

const fetchUserHouseholdCertifications = async (user) => {
  try {
    const { data: userData } =
      await userService.fetchUserHouseholdCertifications(user.id);
    const certifications = mapCertifications(userData.household_members);
    const activeCertifications = certifications.filter((certification) => {
      return certification.is_active;
    });
    activeCertifications.sort(sorCertificationByCreationDate);
    return activeCertifications || [];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    return null;
  }
};

const mapCertifications = (data) => {
  const householdMember = Array.isArray(data) ? data : [data];
  const certificationsMap = [];
  (householdMember || []).forEach((member) => {
    const certifications = member.household?.certifications || [];
    const neededSignatures = [];
    certifications.forEach((certification, index) => {
      certification.form_signatures_request
        .filter((form) => form.requested)
        .forEach((forms_requested) => {
          const filteredSignatures = forms_requested.needed_signatures.filter(
            (signature) =>
              !signature.is_date && !signature.is_name && !signature.compliance
          );
          neededSignatures.push(...filteredSignatures);
        });
      certification.neededSignatures = neededSignatures;
      certifications[index].member_type = member.member_type;
      certificationsMap.push(certifications[index]);
    });
  });
  return certificationsMap;
};

const sorCertificationByCreationDate = (currentItem, nextItem) => {
  const currentItemCreationDate = new Date(currentItem.created);
  const nextItemCreationDate = new Date(nextItem.created);
  if (currentItemCreationDate < nextItem) {
    return 1;
  }
  if (currentItemCreationDate > nextItemCreationDate) {
    return -1;
  }
  return 0;
};

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  GET_USER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  MODIFY_ACTIVE_CERTIFICATIONS: (state, action) => {
    const { activeCertifications } = action.payload;
    return {
      ...state,
      user: {
        ...state.user,
        activeCertifications: activeCertifications,
      },
    };
  },
  UPDATE_USER_PROFILE: (state, action) => {
    const { updatedData } = action.payload;
    return {
      ...state,
      user: {
        ...state.user,
        ...updatedData,
      },
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  extendCurrentSession: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const rDispatch = useDispatch();
  const { message, sendMessage } = useBroadcastChannel('auth-channel');

  useEffect(() => {
    if (!message) {
      return;
    }
    if (message.refresh) {
      if (!state.isAuthenticated) {
        window.location.reload();
      }
    }
  }, [message, state.isAuthenticated]);

  const enableChatWidget = useCallback(() => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.load();
      return;
    }
    window.hsConversationsOnReady = [
      () => {
        window.HubSpotConversations?.widget.load();
      },
    ];
  }, []);

  const startHubspotAuthentication = useCallback(() => {
    window.hsConversationsSettings = {
      loadImmediately: false,
      enableWidgetCookieBanner: true,
    };
    userService
      .authenticateHubspotUser()
      .then((response) => {
        const { token } = response.data;
        window.hsConversationsSettings = {
          ...window.hsConversationsSettings,
          identificationEmail: state.user.email,
          identificationToken: token,
        };
        enableChatWidget();
      })
      .catch(() => {
        console.error('Something went wrong authenticating the user');
        enableChatWidget();
      });
  }, [state?.user?.email, enableChatWidget]);

  useEffect(() => {
    if (state.isInitialized && state.isAuthenticated) {
      const script = document.createElement('script');
      script.src = '//js.hs-scripts.com/47354623.js';
      script.async = true;
      script.defer = true;
      script.type = 'text/javascript';
      script.id = 'hs-script-loader';

      if (!document.getElementById('hs-script-loader')) {
        document.head.appendChild(script);
        startHubspotAuthentication();
      }

      return () => {
        const existingScript = document.getElementById('hs-script-loader');
        if (existingScript) {
          document.head.removeChild(existingScript);
        }
      };
    }
  }, [state.isInitialized, state.isAuthenticated, startHubspotAuthentication]);

  useEffect(() => {
    const initialize = async () => {
      let payload = {
        isAuthenticated: false,
        user: null,
      };
      try {
        removeUserPilotFromLocalStorage();
        if (!isTokenMetaValid('refreshTokenMetadata')) {
          cleanupTokens();
          dispatch({
            type: 'INITIALIZE',
            payload,
          });
          return;
        }

        const { payload: flags } = await rDispatch(getFeatureFlags());
        const { data: userResponse } = await axios.get(
          'user_profile/user/current_profile/?expand=household_member.member_type,household_member.preferred_language,household_members.member_type,household_members.preferred_language,badge'
        );
        const user = await buildUserAuthPayload(userResponse, flags);
        await getUserCertifications(user);
        payload = {
          isAuthenticated: true,
          user,
        };
      } catch (err) {
        console.error(err);
      } finally {
        dispatch({
          type: 'INITIALIZE',
          payload,
        });
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rDispatch]);

  const getUser = async () => {
    let payload = {};
    try {
      const { data: userResponse } = await axios.get(
        'user_profile/user/current_profile/?expand=household_member.member_type,household_member.preferred_language,household_members.member_type,household_members.preferred_language,badge'
      );
      const user = await buildUserAuthPayload(userResponse);
      payload = {
        user,
      };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch({
        type: 'GET_USER',
        payload,
      });
    }
  };

  const getUserCertifications = async (user) => {
    const currentRoles = user.groups.map((role) => role.name.toLowerCase());
    if (currentRoles.includes('resident')) {
      user.activeCertifications = await fetchUserHouseholdCertifications(user);
      if (user.activeCertifications?.length === 1) {
        setNewTenantCertification(user, user.activeCertifications[0]);
        localStorage.setItem(
          'tenantCurrentCertificationID',
          JSON.stringify(user.activeCertifications[0].id)
        );
        return;
      }
      const localStorageCertificationID = localStorage.getItem(
        'tenantCurrentCertificationID'
      );
      if (localStorageCertificationID) {
        const currentCertificationID = JSON.parse(localStorageCertificationID);
        const certificationIndex = user.activeCertifications.findIndex(
          (certification) => certification.id === currentCertificationID
        );
        if (certificationIndex !== -1) {
          setNewTenantCertification(
            user,
            user.activeCertifications[certificationIndex]
          );
        } else {
          localStorage.removeItem('tenantCurrentCertificationID');
        }
      }
    }
  };

  const setNewTenantCertification = (user, certification) => {
    user.currentCertification = certification;
    rDispatch(setCertification(certification));
    rDispatch(setHousehold(certification?.household || null));
    rDispatch(setMemberType(certification?.member_type?.name || ''));
    rDispatch(setHouseholdMember(certification?.household) || null);
  };

  const login = async (access, refresh) => {
    setSessionTokenDetails(access, refresh);

    const { payload: flags } = await rDispatch(getFeatureFlags());
    const { data: userResponse } = await axios.get(
      'user_profile/user/current_profile/?expand=household_member.member_type,household_member.preferred_language,badge'
    );
    sendMessage({ refresh });
    const user = await buildUserAuthPayload(userResponse, flags);
    await getUserCertifications(user);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const extendCurrentSession = ({ access, refresh }) => {
    setSessionTokenDetails(access, refresh);
  };

  const logout = () => {
    removeTokensMetadata();
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = async (email) => {
    return await fetch(
      (process.env.REACT_APP_API_ROOT || 'http://localhost:8000/api/') +
        'user_profile/request-password-reset-email/',
      {
        method: 'POST',
        body: JSON.stringify({ email: email }),
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'omit',
      }
    );
  };

  const updatePassword = async (data) => {
    await axios.patch('user_profile/password-reset-complete/', data);
  };

  const modifyActiveCertifications = async (activeCertifications) => {
    dispatch({
      type: 'MODIFY_ACTIVE_CERTIFICATIONS',
      payload: { activeCertifications: activeCertifications },
    });
  };

  const updateUserProfile = async (updatedData) => {
    dispatch({
      type: 'UPDATE_USER_PROFILE',
      payload: { updatedData },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        extendCurrentSession,
        logout,
        resetPassword,
        updatePassword,
        setNewTenantCertification,
        modifyActiveCertifications,
        updateUserProfile,
        getUserCertifications,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
